import { defineMessages } from 'react-intl'

/* eslint-disable max-len */

export default defineMessages({
  smmMainTeaserTitle: 'Modernization and Individualization <br/>of a Tourism Platform <br/>for S-Markt und Mehrwert',
  smmSectionTitle: 'S-Markt und Mehrwert - <br/>Distribution Strategist for the Sparkassen-Finanzgruppe',
  smmSectionSectionTitle: 'The company',
  smmSectionText: '<p>S-Markt und Mehrwert is a service provider and partner for distribution and marketing strategies for clients within the Sparkassen-Finanzgruppe.</p><br/><p>The company aimed to enhance security, flexibility, efficiency, and modernization to make its services more appealing to Sparkassen and their travel products, ultimately increasing customer satisfaction.</p>',
  smmChallengeTitle: 'The challenge',
  smmChallengeSectionTitle: 'Modernization and Individualization <br/>of the online sales platform',
  smmChallengeSectionText: '<p>The challenge involved replacing an outdated platform with a new, manageable whitelabel system while significantly expanding and optimizing the capabilities of client management.</p><br/><p>This included autonomously creating over 80 Sparkassen as clients. The sophisticated and highly complex client management via freshMS® allowed the creation of marketing pages and customization of modules and content down to teaser level, which could be controlled differently for each client.</p><br/><p>Additionally, the integration of various service and third-party APIs was to enhance functionality.</p>',
  smmImplementationTitle: 'The implementation',
  smmImplementationSectionTitle: 'Agile Web Development and<br/>Tailored Solutions',
  smmImplementationSectionText: '<p>The approach adopted was agile web development, starting with a comprehensive two-month conceptualization phase. The project was executed in multiple phases:</p>',
  smmImplementationListItem1Title: 'Initial Project and Consultation',
  smmImplementationListItem1Text: 'Focus was on developing the whitelabel system, client management, and customization options for clients. Customer consultation and training were essential during this phase.',
  smmImplementationListItem2Title: 'Maintenance (Scrum)',
  smmImplementationListItem2Text: 'After the go-live, the platform was further developed in an agile Scrum model. Continuous implementation of API integrations, optimization of refund communication, implementation of customer login, and other features were carried out.',
  smmTechSectionTitle: 'Tech & Services',
  smmTechSectionText: "Technologies such as Strapi, Amadeus, Kubernetes Hosting, and freshcells' in-house products like freshMS®, QuickStart, and Fusion were utilized to meet these high requirements. Direct integration with URV via Amadeus was facilitated.",
  smmConclusionSectionTitle: 'Conclusion',
  smmConclusionTitle: 'Successful Modernization and <br/>Flexibility',
  smmConclusionSectionText: "Through close collaboration and agile project management, freshcells successfully met the requirements of S-Markt und Mehrwert. The platform's modernization, extensive customization options, and efficient project implementation resulted in outstanding outcomes and a satisfied customer. The partnership between the companies contributed to making the tourism platform future-ready and better aligned with end customer needs.",
  smmConclusionButtonLabel: 'Get free consultation'
})
